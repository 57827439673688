import { registerWithGoogleProvider } from 'api/register';
import { deleteAccount, getUser } from 'api/user';
import {
    GoogleAuthProvider,
    isSignInWithEmailLink,
    onAuthStateChanged,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithPopup,
    signOut
} from 'firebase/auth';
import { makeAutoObservable, runInAction } from 'mobx';
import { setAuthToken } from 'utils/setAuthorizationHeader';
import { auth } from '../firebase';
import { UpdateProfileDto, User } from 'interface/user.interface';
import { updateUserAvatar, updateUserProfile } from 'api/profile';
import _ from 'lodash';
import Smartlook from 'smartlook-client';
import { toast } from 'react-toastify';



class AuthStore {
    currentUser: User | null = null;
    loading = true;

    constructor() {
        makeAutoObservable(this);

        // Initialize user state
        this.initUserState();
    }

    async initUserState() {
        onAuthStateChanged(auth, async (firebaseUser) => {
            console.log('Is logged in:', firebaseUser);

            if (firebaseUser) {
                try {
                    const accessToken = await firebaseUser.getIdToken();
                    setAuthToken(accessToken);
                    const { data: user } = await getUser();
                    runInAction(() => {
                        this.currentUser = user;
                        this.loading = false;
                    });
                    const isNotLocal = window.location.hostname !== 'localhost';
                    if (isNotLocal && user) {
                        // Set user ID in Smartlook after login
                        Smartlook.identify(user.id, { name: user.profile.fullName });
                    }

                } catch (error) {
                    runInAction(() => {
                        this.currentUser = null;
                        this.loading = false;
                    });
                    // Handle sign-out or redirect to login
                }
            } else {
                runInAction(() => {
                    this.currentUser = null;
                    this.loading = false;
                });
                // Handle redirect to login if needed
            }
        });
    }

    async googleSignIn() {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const { data: user } = await registerWithGoogleProvider(result.user);

        if (user) {
            this.currentUser = user;
        }
        // Update user state or navigate post-login
    }

    async logOut() {
        await signOut(auth);
        // this.currentUser = null;
        // Handle post-logout navigation or state cleanup
    }

    async sendSignInLinkToEmailHandler(email: string) {
        window.localStorage.setItem('emailForSignIn', email);
        const actionCodeSettings = {
            url: `${window.location.origin}/sign-in-completed`,
            handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    }

    isSignInWithEmailLinkHandler(emailLink: string) {
        return isSignInWithEmailLink(auth, emailLink);
    }

    async signInWithEmailLinkHandler(email: string, emailLink: string) {
        const result = await signInWithEmailLink(auth, email, emailLink);
        await registerWithGoogleProvider(result.user);
        window.localStorage.removeItem('emailForSignIn');
        // Handle navigation or state update post-sign-in
        if (result) {
            return true
        }
        return false
    }

    setLoading(status: boolean) {
        this.loading = status;
    }

    async updateProfile(updatedUser: UpdateProfileDto) {
        if (_.isEmpty(this.currentUser)) {
            return
        }

        await updateUserProfile(updatedUser);

        toast.success('Profile updated successfully', { autoClose: 5000, theme: "light" });
        
        if (this.currentUser && this.currentUser.profile) {
            this.currentUser.profile = {
                ...this.currentUser.profile,
                ...updatedUser,
            };
        }
    }

    deleteAccount = async () => {
        if (this.currentUser) {
            try {
                await deleteAccount();
                window.location.href = '/login';
            } catch (error) {
                console.error('Error deleting account:', error);
            }
        }
    }

    async updateAvatar(formData: FormData) {
        const { data: avatarUrl } = await updateUserAvatar(formData)
        if (this.currentUser) {
            this.currentUser.profile.avatar = avatarUrl
        }
    }
}

export const authStore = new AuthStore();
