import { Dialog, Transition } from "@headlessui/react";
import { getMetadata } from "api/metadata";
import Autocomplete from "components/AutoComplete/AutoComplete";
import LocationAutocomplete from "components/Common/LocationAutocomplete";
import FooterNav from "components/FooterNav";
import Label from "components/Label/Label";
import { Metadata } from "containers/OnBoarding/OnBoardingStep2";
import { UpdateProfileDto } from "interface/user.interface";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import React, { FC, Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { authStore } from "stores/AuthStore";
import CommonLayout from "./CommonLayout";
import ButtonThird from "shared/Button/ButtonThird";
import { onboardingStore } from "stores/OnboardingStore";
import Checkbox from "shared/Checkbox/Checkbox";
import { Languages } from "data/languages";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { currentUser } = authStore;
  const [metadata, setMetdata] = useState<Metadata>({
    industries: [],
    roles: [],
  });
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);

  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const [formFields, setFormFields] = useState<UpdateProfileDto>({
    id: currentUser?.id || "",
    fullName: currentUser?.profile.fullName,
    companyName: currentUser?.profile.companyName || "",
    industry: currentUser?.profile.industry,
    role: currentUser?.profile.role,
    placeDescription: currentUser?.profile.placeDescription,
    interests: currentUser?.profile.interests,
    avatar: currentUser?.profile.avatar,
    birthDate: currentUser?.profile.birthDate,
    phone: currentUser?.profile.phone || "",
    languages: currentUser?.profile?.languages || [],
    bio: currentUser?.profile.bio || "",
  });

  // State to handle delete account popup visibility
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  useEffect(() => {
    getMetadata().then((res) => {
      const { data } = res;
      setMetdata(data);
    });
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    try {
      authStore
        .updateProfile(formFields)
        .then(() => toast.success("Profile updated successfully"));
    } catch (error) {
      toast.error("An error occurred. Please try again");
    }
  };

  const handleLocationResult = (locationDetails: google.maps.GeocoderResult) => {
    const { formatted_address } = locationDetails;
    const placeDescription = formatted_address;
    setFormFields((prev) => ({
      ...prev,
      placeDescription,
      locationDetails,
    }));
  };

  const changeAvatar = (file: File) => {
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size should be less than 1MB", { theme: "light" });
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    authStore
      .updateAvatar(formData)
      .then(() => toast.success("Avatar updated successfully", { theme: "light" }))
      .catch(() => toast.error("An error occurred. Please try again", { theme: "light" }));
  };

  const handleChanges = (key: string, newRole: string) => {
    setFormFields((prev) => ({
      ...prev,
      [key]: newRole,
    }));
  };

  // Function to delete account
  const handleDeleteAccount = () => {
    authStore
      .deleteAccount()
      .then(() => {
        toast.success("Account deleted successfully", { theme: "light" });
        // Handle any necessary redirects or state updates after account deletion
      })
      .catch(() => toast.error("An error occurred while deleting the account", { theme: "light" }));
    setShowDeletePopup(false);
  };

  const handleLanguagesChanges = (shouldAdd: boolean, langName: string) => {
    if (shouldAdd) {
      // Add the language if it's not already present
      if (!formFields?.languages?.includes(langName)) {
        formFields?.languages?.push(langName);
      }
    } else {
      // Remove the language if it's present
      const index = formFields?.languages?.indexOf(langName);
      if (index && index !== -1) {
        formFields?.languages?.splice(index, 1);
      }
    }
    setFormFields((prev) => ({
      ...prev,
      languages: formFields?.languages,
    }));
  }

  const renderMoreFilterItem = (
    data: {
      name: string;
      nativeName?: string;
      defaultChecked?: boolean;
    }[]
  ) => {

    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-y-7 gap-x-4 sm:gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.nativeName}
              label={item.name}
              defaultChecked={formFields?.languages?.includes(item.name)}
              onChange={(checked) => handleLanguagesChanges(checked, item.name)}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.nativeName}
              label={item.name}
              defaultChecked={formFields?.languages?.includes(item.name)}
              onChange={(checked) => handleLanguagesChanges(checked, item.name)}
            />
          ))}
        </div>
      </div>
    );
  };


  const renderChooseLang = () => {
    return (
      <div className="">
        {<Label>Language</Label>}

        <div
          className={`flex items-center justify-center px-4 py-3 sm:px-6 text-sm rounded-full border text-neutral-700 dark:text-neutral-200 cursor-pointer`}
          onClick={openModalMoreFilter}
        >

          <span>Choose Your Languages</span>
          {/* {renderXClear()} */}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 sm:px-6 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h3 className="text-xl font-medium ">
                          Choose Your Languages
                        </h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(Languages)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        onboardingStore.clearLanguages()
                        closeModalMoreFilter()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <h2 className="text-3xl font-semibold">Account information</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" imgUrl={formFields?.avatar} />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) {
                      changeAvatar(e.target.files[0]);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>Username</Label>
                <Input
                  className="mt-1.5"
                  defaultValue={formFields?.fullName || ""}
                  name="fullName"
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
              <div className="flex-1">
                  <Label>Date of birth</Label>
                  <Input
                    className="w-full"
                    type="date"
                    defaultValue={moment(formFields?.birthDate).format("YYYY-MM-DD")}
                    name="birthDate"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex-1">
                  <Label>Address</Label>
                  <LocationAutocomplete
                    address={formFields?.placeDescription || ""}
                    onLocationSelect={handleLocationResult}
                  />
                </div>
                <div className="flex-1">
                  {renderChooseLang()}
                </div>
              </div>

              <div>
                <Label>Role</Label>
                <Autocomplete
                  defaultValue={formFields?.role}
                  options={metadata.roles.map((r) => r.value)}
                  placeholder="Select your role"
                  onChange={(val) => handleChanges("role", val)}
                />
              </div>
              <div>
                <Label>Industry</Label>
                <Autocomplete
                  defaultValue={formFields?.industry}
                  options={metadata.industries.map((r) => r.value)}
                  placeholder="Select your industry"
                  onChange={(val) => handleChanges("industry", val)}
                />
              </div>
              <div>
                {renderChooseLang()}
              </div>
              <div>
                <Label>About you</Label>
                <Textarea
                  className="mt-1.5"
                  defaultValue={formFields?.bio}
                  name="bio"
                  onChange={handleInputChange}
                />
              </div>
              <div className="pt-2 flex space-x-4">
                <ButtonPrimary onClick={handleSubmit}>Update Info</ButtonPrimary>
                <ButtonPrimary
                  className="bg-red-600 text-white px-4 py-2"
                  onClick={() => setShowDeletePopup(true)}
                >
                  Delete Account
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
      <FooterNav />

      {/* Modal using Headless UI */}
      <Dialog
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        className="fixed z-50 inset-0 flex items-center justify-center"
      >
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
        <div className="bg-white p-6 rounded-lg max-w-md w-full z-10">
          <Dialog.Title className="text-xl font-bold">
            Confirm Account Deletion
          </Dialog.Title>
          <Dialog.Description className="mt-2">
            Are you sure you want to delete your account? This action cannot be undone.
          </Dialog.Description>
          <div className="mt-4 flex justify-end space-x-4">
            <button
              className="px-4 py-2 bg-gray-300 rounded-md"
              onClick={() => setShowDeletePopup(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-600 text-white rounded-md"
              onClick={handleDeleteAccount}
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default observer(AccountPage);
